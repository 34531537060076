.vision {
  margin:6.5vh 5vh 4vh 5vh;
  background-color:rgba(255, 255, 255, 0.8);
  height: max-content;
  }
.vision .heading {
  color: #000;
  text-align: center;
  font-family: Odor Mean Chey;
  font-size: 11vh;
  font-weight: 400;
  padding:2vh 2vh;
  }
.vision_body{
  display: grid;
  grid-template-columns: 40% 60%;
  }
.vision .content {
  margin-right: 7vh;
  padding:5vh 2vh ;
  color: #000;
  font-family: Inter;
  font-size: 4vh;
  opacity: 0;
  text-wrap: wrap;
  text-align:justify;

}

.vision .vision_image{
  transform: scale(0);
  transform-origin: center; 

}
@media(max-width:770px){
  .vision{
    background-color: rgba(255, 255, 255, 0.8);
  }
  .vision_body{
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;
  }
 
  .vision .content{
    font-size: 3vw;
    margin:0 5vw ;
   
  } 
}