.product{
  height: 90vh;
  margin-top: 10vh;
  display: grid;
  grid-template-rows: 20% auto;
  align-items: center;
}

.proHead{
  text-align: center;
  font-family: 'katibeh';
  font-size: 17vh;
  text-shadow: 0.8vh 0.3vh 0.5vh #6f6f6f;
}

.container{
  /* position: relative; */
  /* margin-top: 40vh; */
  display: grid;
  height: 100%;
  grid-template-columns:10% 80% 10%;
  align-items: center;
}

.item{
  padding-top: 1.3vh;
  font-size: 2vh;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.717);
  background-repeat: no-repeat;
  width:12.5vw;
  height:16vw;
  background-position: 0% 100%;
  display: inline-block;
  transition: 0.5s;
  background-size: contain;
  position: absolute;
  transform: translate(-50%,-50%);
  border-radius: 2vw;
  box-shadow:  1vh 1vh 3vh #2b2b2b;
  border: 1px black solid;
  
}

.item:nth-child(1),
.item:nth-child(2){
  display: none;
  box-shadow: none;
}
.item:nth-child(3){
  left:20%;
}
.item:nth-child(4){
  left:45%;
  margin-top: -9%;
  transform:scale(150%);
 
}
.item:nth-child(5){
 right:5%;
  
}
.item:nth-child(n+6){
  float: right;
  opacity: 0;
}

.buttons button{
 
  position: relative;
  width:7vh;
  height:7vh;
  border-radius: 50%;
  border:1vh solid #555;
  transition: 0.5s;
}
.buttons button:hover{
  background-color: #cfcfcf;
  cursor: pointer;
}

.next{
  
  float: right;
  margin-right:1vw;
}

.prev{

  float: left;
  margin-left:1vw;
}


@media(max-width:900px){
  .product{
    height:100vw;
    margin-top: 1vw;
  }
  .proHead{
    white-space: nowrap;
    font-size: 15vw;
  }
  .item:nth-child(5){
    display:none;
    box-shadow: none;
  }
  .item:nth-child(3),
  .item:nth-child(5){
    display: none;    
    box-shadow: none;
  }
  .item:nth-child(4){
     left:35vw;
     margin-top:-15vw;
   }
   .item{
    padding: 0.8vw;
    font-size: 2.0vw;
    width:25vw;
    height:33vw;
    position: absolute;
    transform: translate(-50%,-50%);
    border-radius: 2vw;
    box-shadow:  1vw 1vw 3vw #2b2b2b;
    border: 1px black solid;
    
  }
  
  .buttons button{
    top:7vw;
    width:7vw;
    height:7vw;
    border-radius: 50%;
    border:0.6vw solid #555;
    
  }
}
@media(max-width:770px){
  .proHead{
    font-size: 20vw;
  }
  .item:nth-child(4){
     left:32.5vw;
   }
   .item{
    padding: 0.8vw;
    font-size: 2.4vw;
    width:30vw;
    height:40vw;
    border-radius: 3vw;
    box-shadow:  1vw 1vw 3vw #2b2b2b;
    
    
  }
  
  .buttons button{
    top:10vw;
    width:10vw;
    height:10vw;
    border-radius: 50%;
    border:1vw solid #555;
    
  }
}
@media(max-width:430px){
 
}