.associations{
  margin: 3% 0px;
  padding: 5vh 0px;
  background-color: white;

  }

.assHead{
  text-align: center;
  font-family: 'katibeh';
  font-size: 18vh;
  text-shadow:  0.8vh 0.3vh 0.5vh #6f6f6f;
  }

.assRow1{
  width: 90vw;
  display: flex;
  gap:3vw;
  align-items: center;
  justify-content: space-evenly;
  margin:auto auto;
  flex-wrap: wrap;
  }

.imgHover{
  transition: transform .2s;
  }
.img1 img,.img2 img,.img3 img,.img4 img,.img5 img{
  margin: 2vh;
  width: 25vh;
  cursor: pointer;
  }

.imgHover:hover{
  transform: scale(1.5);   
  }

.img6 img,.img8 img{
  width: 25vh;
  cursor: pointer;
  }

.img7 img{
  width: 27vh;
  cursor: pointer;
  }

.img9 img{
  height: 22vh;
  cursor: pointer;
  }
  
.img10 img{
  width: 17vh;
  }
  @media(max-width:770px){
    .associations{
      width: 100%;
    }
    .assHead{
      font-size:18vw;
    }
    .img1 img,.img2 img,.img3 img,.img4 img,.img5 img{
      margin: 2vh;
      width: 40vw;
      padding:7vw;
    }
    .img6 img,.img8 img{
      width: 40vw;
      padding:7vw;
    }
    .img7 img{
      width: 50vw;
      padding:6vw;
    }
    .img9 img{
      height: 36vw;
      cursor: pointer;
      padding:7vw;
      }
      
    .img10 img{
      width: 35vw;
      padding:7vw;
      }
  }