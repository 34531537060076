@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

*{
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

.App {
  text-align: center;
}

.parallax {
  width: 100vw;
  height: 100vh;
  background: url('./Components/Assets/parallax-image.png') lightgray 50% / cover no-repeat;
  position: fixed;
  z-index: -1;
}